import i18n from "@/config/i18n";
export default function(password) {
  return {
    min_8_charaters: {
      content: i18n.t("password_validations.minimum_8_characters"),
      validator: () => {
        return password.length >= 8;
      }
    },
    has_a_capital_letter: {
      content: i18n.t("password_validations.atleast_one_capital"),
      validator: () => {
        const re = /([A-Z]{1})+/;
        return re.test(password);
      }
    },
    has_a_small_letter: {
      content: i18n.t("password_validations.atleast_one_small"),
      validator: () => {
        const re = /([a-z]{1})+/;
        return re.test(password);
      }
    },
    has_a_number: {
      content: i18n.t("password_validations.atleast_one_number"),
      validator: () => {
        const re = /([0-9]{1})+/;
        return re.test(password);
      }
    },
    has_a_special_characters: {
      content: i18n.t("password_validations.atleast_one_special_character"),
      validator: () => {
        const re = /[^A-Za-z0-9\s]/;
        return re.test(password);
      }
    }
  };
}

/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: resizeContainerMixin.js
Description: This mixin contains the common events emitted when container resized.
*/
import {
  addResizeListener,
  removeResizeListener
} from "element-ui/src/utils/resize-event";

export default {
  methods: {
    rcm__debounce: function(ms, fn) {
      var timer;
      return function() {
        clearTimeout(timer);
        var args = Array.prototype.slice.call(arguments);
        args.unshift(this);
        timer = setTimeout(fn.bind.apply(fn, args), ms);
      };
    },
    rcm__registerResizeObserver(componentRef, func, debounceMilliSecs = 100) {
      this.$nextTick(() => {
        addResizeListener(
          this.$refs[componentRef].$el,
          this.rcm__debounce(debounceMilliSecs, () => {
            requestAnimationFrame(func);
          })
        );
      });
    },
    rcm__unregisterResizeObserver(componentRef, func, debounceMilliSecs = 100) {
      removeResizeListener(
        this.$refs[componentRef].$el,
        this.rcm__debounce(debounceMilliSecs, () => {
          requestAnimationFrame(func);
        })
      );
    },
    rcm__registerResizeObserverOnHTMLElement(HTMLElement, func) {
      this.$nextTick(() => {
        addResizeListener(HTMLElement, () => {
          requestAnimationFrame(func);
        });
      });
    },
    rcm__unregisterResizeObserverOnHTMLElement(HTMLElement, func) {
      removeResizeListener(HTMLElement, () => {
        requestAnimationFrame(func);
      });
    }
  }
};

<script>
import passwordFieldValidations from "@/constants/validations/password";
import superUserPasswordFieldValidations from "@/constants/validations/superUserPassword";
import resizeContainerMixin from "@/mixins/resizeContainerMixin";
import filtersMixin from "@/mixins/filtersMixin";
export default {
  mixins: [resizeContainerMixin, filtersMixin],
  data() {
    return {
      pvm__passwordStrengthColor: [
        "#F24654",
        "#FE9B3D",
        "#FFCC2F",
        "#32DB28",
        "#35A22E"
      ],
      pvm__passwordPercentage: {
        color: "white",
        width: 0,
        left: 0
      },
      pvm__container_ref: undefined,
      pvm__field_ref: undefined
    };
  },
  computed: {
    pvm__passwordValidations() {
      const password = this.user.password || "";
      return this.pvm__passwordFieldValidations(password);
    }
  },
  beforeDestroy() {
    if (this.pvm__container_ref) {
      this.rcm__registerResizeObserverOnHTMLElement(
        this.pvm__container_ref,
        this.rcm__debounce(300, this.pvm__getPercentage)
      );
    }
  },
  methods: {
    pvm__passwordFieldValidations: passwordFieldValidations,
    pvm__superUserPasswordFieldValidations: superUserPasswordFieldValidations,
    setRefsForPasswordValidations(containerRef, fieldRef) {
      this.pvm__container_ref = containerRef;
      this.pvm__field_ref = fieldRef;
      if (this.pvm__container_ref) {
        this.rcm__registerResizeObserverOnHTMLElement(
          this.pvm__container_ref,
          this.rcm__debounce(300, this.pvm__getPercentage)
        );
      }
    },
    pvm__getPercentage() {
      this.rcm__debounce(300, () => {
        if (!this.pvm__field_ref) return;
        const passwordElement = this.pvm__field_ref.$refs.ErInput.$refs.input;
        const parent = passwordElement.offsetParent;
        const [parentComputeRects, childComputeRects] = [
          parent,
          passwordElement
        ].map((x) => x.getBoundingClientRect());
        const inputWidth = childComputeRects.width;
        this.pvm__passwordPercentage.left =
          parentComputeRects.width - childComputeRects.width;
        const validations = Object.values(this.pvm__passwordValidations);
        const totalValidations = validations.length;
        const noOfPassed = validations.filter((x) => x.validator()).length;
        this.$nextTick(() => {
          this.pvm__passwordPercentage.color =
            this.pvm__passwordStrengthColor[noOfPassed - 1];
          this.pvm__passwordPercentage.width =
            inputWidth * (noOfPassed / totalValidations);
        });
      })();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/_passwordvalidation.scss";
@include password-validation-mixin;
</style>
